<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-right">Alianzas</h1>
    </div>

    <DataGrid :configuracion="datagrid_config" :data="alianzas" @ready="buscar_btns">
      <button class="btn btn-warning mr-2" @click="obtener_alianzas">Recargar</button>
      <button class="btn btn-primary mr-2" @click="ver_modal_alianza=true">Nueva alianza</button>
    </DataGrid>

    <Info v-if="ver_modal_alianza" :alianza_id="alianza_id" @close="cerrar_modal_alianza" @update="refrescar_alianzas" />
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3';

import Info from './Alianza/Info'

import api from '@/apps/fyc/api/alianzas';

export default {
  components: {
    DataGrid, Info
  },
  data() {
    return {
      datagrid_config: {
        name: 'alianzas',
        selector: false,
        cols: {
          nombre: 'Nombre',
          estatus: 'Estatus',
          created_at: 'Creación',
          otro: ''
        },
        mutators: {
          created_at(val, row, vue) {
            return vue.$moment(val).format('ddd DD MMM YYYY hh:mm');
          },
          otro(val, row) {
            return '<button class="btn btn-outline-primary alianza-btn" data-id="'+row.id+'"><i class="fa-solid fa-pencil"></i></button>'
          }
        }
      },
      alianzas: [],
      alianza_id: null,
      ver_modal_alianza: false
    }
  },
  mounted() {
    this.obtener_alianzas();
  },
  methods: {
    async obtener_alianzas() {
      try {
        this.alianzas = (await api.obtener_alianzas()).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    buscar_btns() {
      document.querySelectorAll('.alianza-btn').forEach(btn => {
        btn.removeEventListener('click', this.acciones_btn);
        btn.addEventListener('click', this.acciones_btn);
      })
    },
    acciones_btn(e) {
      let btn = e.target;

      if (btn.tagName == 'I')
        btn = e.target.parentNode;

      this.alianza_id = btn.dataset.id;
      this.ver_modal_alianza = true;
    },
    refrescar_alianzas() {
      this.alianza_id = null;
      this.ver_modal_alianza = false;
      this.obtener_alianzas();
    },
    cerrar_modal_alianza() {
      this.alianza_id = null;
      this.ver_modal_alianza = false;
    }
  }
}
</script>

<style lang="scss">
  .dataGrid_alianzas {
    thead {
      tr {
        th:nth-child(1) {
          width: 5%;
        }
        th:nth-child(2) {
          width: 55%;
        }
        th:nth-child(3) {
          width: 10%;
        }
        th:nth-child(4) {
          width: 15%;
        }
        th:nth-child(5) {
          width: 10%;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        td:nth-child(5) {
          text-align: center;
        }
      }
    }
  }
</style>